import React from "react";
import RowGroup from "./RowGroup";
import TableRow from "./TableRow";

function BowlerList({ bowling, team, current = false, sbType }) {
  return (
    <RowGroup groupClass="players">
      <TableRow
        text="Bowlers"
        columns={["O", "M", "R", "W", "ECON"]}
        isHeader={true}
      />

      {bowling &&
        !current &&
        bowling.map((bowl, index) => {
          if (bowl?.scoreboard === sbType) {
            return (
              <TableRow
                key={index}
                text={`${bowl?.bowler?.fullname} ${bowl?.active ? "*" : ""}`}
                columns={[
                  bowl?.overs,
                  bowl?.medians,
                  bowl?.runs,
                  bowl?.wickets,
                  bowl?.rate,
                ]}
              />
            );
          } else {
            return "";
          }
        })}

      {bowling &&
        current &&
        bowling.map((bowl, index) => {
          if (bowl?.active) {
            return (
              <TableRow
                key={index}
                text={bowl?.bowler?.fullname + "*"}
                columns={[
                  bowl?.overs,
                  bowl?.medians,
                  bowl?.runs,
                  bowl?.wickets,
                  bowl?.rate,
                ]}
              />
            );
          } else {
            return "";
          }
        })}

      {/* <TableRow
        text="Michael Johnson *"
        columns={['3.2', '0', '32', '1', '10.12']}
      /> */}
    </RowGroup>
  );
}

export default BowlerList;
