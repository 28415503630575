export const GET_MATCH_DATA = 'get_match_data';
export const GET_MATCH_DATA_SUCCESS = 'get_match_data_success';
export const GET_MATCH_DATA_ERROR = 'get_match_data_error';

// Match Detail Api for live
export const SET_MATCH_DETAIL_DATA = 'set_match_detail_data';
export const GET_MATCH_DETAIL_DATA = 'get_match_detail_data';
export const GET_MATCH_DETAIL_DATA_SUCCESS = 'get_match_detail_data_success';
export const GET_MATCH_DETAIL_DATA_ERROR = 'get_match_detail_data_error';

export const GET_COMPLETED_MATCH_DATA = 'get_completed_match_data';
export const GET_COMPLETED_MATCH_DATA_SUCCESS =
  'get_completed_match_data_success';
export const GET_COMPLETED_MATCH_DATA_ERROR = 'get_completed_match_data_error';

export const GET_UPCOMING_MATCH_DATA = 'get_upcoming_match_data';
export const GET_UPCOMING_MATCH_DATA_SUCCESS =
  'get_upcoming_match_data_success';
export const GET_UPCOMING_MATCH_DATA_ERROR = 'get_upcoming_match_data_error';

// Squads
export const GET_SQUAD_DATA = 'get_squad_data';
export const GET_SQUAD_DATA_SUCCESS = 'get_squad_data_success';
export const GET_SQUAD_DATA_ERROR = 'get_squad_data_error';
