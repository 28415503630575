import React from 'react';
import AvatarIcon from '../assets/images/user.svg';
function TeamPlayer({ fullname, position, image_path }) {
  return (
    <li className="player">
      <img
        src={
          image_path === 'https://cdn.sportmonks.com' ? AvatarIcon : image_path
        }
        alt={fullname}
      />
      <h4>
        {fullname} <span>{position?.name}</span>
      </h4>
    </li>
  );
}

export default TeamPlayer;
