import {
  GET_MATCH_DATA,
  GET_MATCH_DATA_SUCCESS,
  GET_MATCH_DATA_ERROR,
  GET_COMPLETED_MATCH_DATA,
  GET_COMPLETED_MATCH_DATA_SUCCESS,
  GET_COMPLETED_MATCH_DATA_ERROR,
  GET_UPCOMING_MATCH_DATA,
  GET_UPCOMING_MATCH_DATA_SUCCESS,
  GET_UPCOMING_MATCH_DATA_ERROR,
} from '../constants/ActionTypes';

const INIT_MATCH_DATA = {
  matchDataLoader: false,
  matchData: [],
  matchCompletedData: [],
  matchUpcomingData: [],
  matchMetaData: {},
  matchDataError: null,
};

const MatchReducer = (state = INIT_MATCH_DATA, action) => {
  switch (action.type) {
    case GET_MATCH_DATA: {
      return {
        ...state,
        matchDataLoader: true,
      };
    }
    case GET_MATCH_DATA_SUCCESS: {
      return {
        ...state,
        matchData: action?.payload?.data || [],
        matchMetaData: action?.payload?.meta,
        matchDataLoader: false,
        matchDataError: null,
      };
    }
    case GET_MATCH_DATA_ERROR: {
      return {
        ...state,
        matchDataError: action.payload,
        matchDataLoader: false,
      };
    }

    // for completed
    case GET_COMPLETED_MATCH_DATA: {
      return {
        ...state,
        matchDataLoader: true,
      };
    }
    case GET_COMPLETED_MATCH_DATA_SUCCESS: {
      return {
        ...state,
        matchCompletedData:
          action?.payload?.meta?.current_page === 1
            ? [...action?.payload?.data]
            : [...state.matchCompletedData, ...action?.payload?.data],
        matchMetaData: action?.payload?.meta,
        matchDataLoader: false,
        matchDataError: null,
      };
    }
    case GET_COMPLETED_MATCH_DATA_ERROR: {
      return {
        ...state,
        matchDataError: action.payload,
        matchDataLoader: false,
      };
    }

    // for upcoming
    case GET_UPCOMING_MATCH_DATA: {
      return {
        ...state,
        matchDataLoader: true,
      };
    }
    case GET_UPCOMING_MATCH_DATA_SUCCESS: {
      return {
        ...state,
        matchUpcomingData:
          action?.payload?.meta?.current_page === 1
            ? [...action?.payload?.data]
            : [...state.matchUpcomingData, ...action?.payload?.data],
        matchMetaData: action?.payload?.meta,
        matchDataLoader: false,
        matchDataError: null,
      };
    }
    case GET_UPCOMING_MATCH_DATA_ERROR: {
      return {
        ...state,
        matchDataError: action.payload,
        matchDataLoader: false,
      };
    }

    default:
      return state;
  }
};

export default MatchReducer;
