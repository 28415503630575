import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { isEmpty } from "../../helpers/Helper";

function MatchCard({ matchData }) {
  const handleMatchStatus = (status) => {
    if (status && status.toUpperCase() === "FINISHED") {
      return "completed";
    } else if (status && status.toUpperCase() === "NS") {
      return "upcoming";
    }
    return "live";
  };

  const renderScore = (runs, teamId) => {
    let yetToBat = true;
    let iterateFlag = 0;
    return (
      <div>
        {runs.map((run, index) => {
          iterateFlag++;
          if (run.team_id === teamId) {
            yetToBat = false;
            return (
              <div className="score" key={index}>
                <span className="run">
                  {isEmpty(run?.score) ? "" : run?.score} -{" "}
                  {isEmpty(run?.wickets) ? "" : run?.wickets}
                </span>
                <span className="over">
                  ({isEmpty(run?.overs) ? "" : run?.overs})
                </span>
              </div>
            );
          } else {
            if (iterateFlag === runs.length) {
              if (yetToBat) {
                return (
                  <div className="yet-to-bat" key={index}>
                    Yet to bat
                  </div>
                );
              } else {
                return "";
              }
            } else {
              return "";
            }
          }
        })}
      </div>
    );
  };

  return (
    <Link to={`/detail/${matchData?.id}`} key={matchData.toString()}>
      {matchData && !isEmpty(matchData.status) && (
        <div className="match-card">
          <div className="match-card-title">
            <h3>
              {isEmpty(matchData?.type) ? "" : matchData?.type + "-"}{" "}
              {isEmpty(matchData?.stage?.name)
                ? ""
                : matchData?.stage?.name + ","}{" "}
              {isEmpty(matchData?.starting_at) ? (
                ""
              ) : (
                <Moment format="YYYY">{matchData?.starting_at}</Moment>
              )}
            </h3>
            <div className="status ml-auto">
              <span className={handleMatchStatus(matchData.status)}>
                {handleMatchStatus(matchData.status) !== null &&
                handleMatchStatus(matchData.status) !== undefined
                  ? handleMatchStatus(matchData.status).toUpperCase()
                  : matchData.status}
              </span>
            </div>
          </div>
          <div className="match-card-meta">
            {isEmpty(matchData.round) ? "" : <span>{matchData.round}</span>}
            {isEmpty(matchData?.venue?.country?.name) ? (
              ""
            ) : (
              <span>{matchData?.venue?.country?.name}</span>
            )}
            {isEmpty(matchData?.starting_at) ? (
              ""
            ) : (
              <span>
                <Moment format="Do MMMM YYYY">{matchData?.starting_at}</Moment>
              </span>
            )}
          </div>
          <div className="match-card-teams row">
            <div className="col-6">
              <div className="media align-items-center">
                {isEmpty(matchData.localteam?.image_path) ? (
                  ""
                ) : (
                  <img
                    src={matchData.localteam?.image_path}
                    className="fflag ff-auto mr-2 mt-2"
                    alt=""
                  />
                )}
                <div className="media-body">
                  <div className="team-name">
                    {isEmpty(matchData?.localteam?.name)
                      ? ""
                      : matchData?.localteam?.name}
                  </div>
                  {isEmpty(matchData?.runs)
                    ? ""
                    : renderScore(
                        matchData?.runs,
                        isEmpty(matchData?.localteam?.id)
                          ? ""
                          : matchData?.localteam?.id
                      )}
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="media align-items-center">
                <img
                  src={matchData.visitorteam?.image_path}
                  className="fflag ff-auto mr-2 mt-2"
                  alt=""
                />
                <div className="media-body">
                  <div className="team-name">
                    {isEmpty(matchData?.visitorteam?.name)
                      ? ""
                      : matchData?.visitorteam?.name}
                  </div>
                  {isEmpty(matchData?.runs)
                    ? ""
                    : renderScore(
                        matchData?.runs,
                        isEmpty(matchData?.visitorteam.id)
                          ? ""
                          : matchData?.visitorteam?.id
                      )}
                </div>
              </div>
            </div>
          </div>
          <div className="match-card-status">{matchData.note}</div>
        </div>
      )}
    </Link>
  );
}

export default MatchCard;
