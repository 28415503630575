import React from 'react';
import EmptyImage from '../../assets/images/ic_empty.svg';
function EmptyResult(props) {
  return (
    <div className="empty-state">
      <div className="empty-state-content text-center">
        <img src={EmptyImage} alt="Empty State" />
        <p className="w-80 mx-auto">{props.text}</p>
      </div>
    </div>
  );
}

export default EmptyResult;
