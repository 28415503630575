import { combineReducers } from 'redux';
import MatchDetailReducer from './MatchDetailReducer';
import MatchReducer from './MatchReducer';
import SquadReducer from './SquadReducer';
const LiveScoreApp = combineReducers({
  matches: MatchReducer,
  matchDetail: MatchDetailReducer,
  squads: SquadReducer,
});

export default LiveScoreApp;
