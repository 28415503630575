import React from 'react';
import TeamPlayer from './TeamPlayer';

function TeamPlayerList({ players }) {
  return (
    <ul className="player-list">
      {players?.length > 0 &&
        players.map((player, index) => <TeamPlayer {...player} key={index} />)}
    </ul>
  );
}

export default TeamPlayerList;
