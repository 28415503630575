import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import RowGroup from "./RowGroup";
import TableRow from "./TableRow";
import TableRowSimple from "./TableRowSimple";

function BatsmenList({
  batting,
  team,
  current = false,
  scoreboard = null,
  sbType,
}) {
  const [extra, setExtra] = useState("");
  const [totalScore, setTotalScore] = useState("");
  useEffectOnce(() => {
    if (
      scoreboard !== null &&
      scoreboard !== undefined &&
      scoreboard?.length > 0
    ) {
      let strExtra = "";
      let strTotal = "";
      let wide = 0;
      let noball = 0;
      let legBye = 0;
      let bye = 0;
      let total = 0;
      let ts = 0;
      let overs = 0;
      let tw = 0;
      // eslint-disable-next-line array-callback-return
      scoreboard
        .filter(function (score) {
          return score.scoreboard === sbType;
        })
        .map((sb) => {
          if (sb.type === "extra") {
            wide += sb.wide;
            noball += sb.noball_balls;
            legBye += sb.leg_bye;
            bye += sb.bye;
            total += wide + noball + legBye + bye;
          }
          if (sb.type === "total") {
            ts += sb.total;
            overs += sb.overs;
            tw = sb.wickets;
          }
        });
      // 5 (b 1, w 0, nb 2, lb 2)
      strExtra = `${total} (b ${bye} w ${wide} nb ${noball} lb ${legBye})`;
      // eslint-disable-next-line no-unused-vars
      strExtra = setExtra(strExtra);
      strTotal = `${ts} (${tw} wickets, ${overs} overs)`;
      setTotalScore(strTotal);
    }
  }, []);
  return (
    <RowGroup groupClass="players">
      <TableRow
        text="Batsmen"
        columns={["R", "B", "4S", "6S", "SR"]}
        isHeader={true}
      />
      {batting &&
        !current &&
        batting
          .filter((bats) => bats.scoreboard === sbType)
          .map((bat, index) => {
            // if (bat?.team_id === team) {
            return (
              <TableRow
                key={index}
                text={`${bat?.batsman?.fullname} ${
                  bat?.active === true ? "*" : ""
                }`}
                columns={[
                  bat?.score,
                  bat?.ball,
                  bat?.four_x,
                  bat?.six_x,
                  ((bat?.score / bat?.ball) * 100)?.toFixed(2),
                ]}
              />
            );
            // }else{
            //   return '';
            // }
          })}
      {/* Extra and total */}
      {batting && !current && (
        <>
          <TableRowSimple text="Extra" value={extra} isHeader={false} />
          <TableRowSimple text="Total" value={totalScore} isHeader={false} />
        </>
      )}
      {
        batting &&
          current &&
          batting
            .filter((bats) => bats.scoreboard === sbType)
            .sort((a, b) => (a.score < b.score ? 1 : -1))
            .slice(0, 3)
            .map((bat, index) => {
              // if (bat?.team_id === team) {
              return (
                <TableRow
                  key={index}
                  text={`${bat?.batsman?.fullname} ${
                    bat?.active === true ? "*" : ""
                  }`}
                  columns={[
                    bat?.score,
                    bat?.ball,
                    bat?.four_x,
                    bat?.six_x,
                    ((bat?.score / bat?.ball) * 100)?.toFixed(2),
                  ]}
                />
              );
              // }else{
              //   return '';
              // }
            })
        // batting.map((bat, index) => {
        //   if (bat.active) {
        //     return (
        //       <TableRow
        //         key={index}
        //         text={bat?.batsman?.fullname + '*'}
        //         columns={[
        //           bat?.score,
        //           bat?.ball,
        //           bat?.four_x,
        //           bat?.six_x,
        //           ((bat?.score / bat?.ball) * 100)?.toFixed(2),
        //         ]}
        //       />
        //     );
        //   }else{
        //     return '';
        //   }
        // })
      }
    </RowGroup>
  );
}

export default BatsmenList;
