import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_MATCH_DETAIL_DATA,
  GET_MATCH_DETAIL_DATA_ERROR,
} from '../constants/ActionTypes';
import { getMatchDetailDataApi } from '../apis/MatchDetailApi';
import { getMatchDetailDataSuccess } from '../actions/MatchDetailAction';

function* fetchMatchDetail(action) {
  try {
    const response = yield call(getMatchDetailDataApi, action.payload);
    if (response !== null && response !== undefined) {
      if (response?.status === 'error') {
        yield put({
          type: GET_MATCH_DETAIL_DATA_ERROR,
          matchDetailDataError: response?.status,
        });
      } else {
        yield put(getMatchDetailDataSuccess(response.data));
      }
    } else {
      yield put({
        type: GET_MATCH_DETAIL_DATA_ERROR,
        matchDetailDataError: 'Something went wrong!',
      });
    }
  } catch (e) {
    yield put({
      type: GET_MATCH_DETAIL_DATA_ERROR,
      matchDetailDataError: e.message,
    });
  }
}

export default function* waitForFetchMatchDetail() {
  yield takeEvery(GET_MATCH_DETAIL_DATA, fetchMatchDetail);
}
