import { baseUrl } from "../config";
import { toQueryString } from "../helpers/Helper";
export const getMatchDataApi = async (params = null) => {
  try {
    let url = `${baseUrl}?type=matches`;

    if (params !== null) {
      url += `&${toQueryString(params)}`;
    }

    const matchDataResponse = await fetch(url, {
      method: "GET",
    });
    return await matchDataResponse.json();
  } catch (errors) {
    return errors;
  }
};
