import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MatchCard from "../components/common/MatchCard";
import Tabs from "../components/common/Tabs";
import CustomAccordion from "../components/common/CustomAccordion";
import TeamSwitcher from "../components/TeamSwitcher";
import TeamWrapper from "../components/TeamWrapper";
import { getMatchDetailData } from "../actions/MatchDetailAction";
import { useDispatch, useSelector } from "react-redux";
import BatsmenList from "../components/common/BatsmenList";
import BowlerList from "../components/common/BowlerList";
import MatchDetailTable from "../components/MatchDetailTable";
import Loader from "../components/common/Loader";
import EmptyResult from "../components/common/EmptyResult";
import { loadJQuery } from "../assets/js/init";
import { getSquadData } from "../actions/SquadAction";
import { isEmpty, stringifyNumber } from "../helpers/Helper";

function Detail() {
  loadJQuery();
  const params = useParams();
  const matchDetailState = useSelector((state) => state.matchDetail);
  const squadState = useSelector((state) => state.squads);
  const [teams, setTeams] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMatchDetailData(params.id));
  }, [params.id, dispatch]);
  const handleTabChange = (tabName) => {
    if (
      tabName === "SQUAD" &&
      !isEmpty(matchDetailState?.matchData) &&
      !isEmpty(matchDetailState?.matchData?.localteam) &&
      !isEmpty(
        matchDetailState?.matchData?.visitorteam &&
          !isEmpty(matchDetailState?.matchData?.season_id)
      )
    ) {
      const squadPayload = {
        seasonId: matchDetailState?.matchData?.season_id,
        teamId: matchDetailState?.matchData?.localteam?.id,
      };
      setTeams([
        {
          id: matchDetailState?.matchData?.localteam?.id,
          name: matchDetailState?.matchData?.localteam?.name,
          rel: "team-one",
        },
        {
          id: matchDetailState?.matchData?.visitorteam?.id,
          name: matchDetailState?.matchData?.visitorteam?.name,
          rel: "team-two",
        },
      ]);
      dispatch(getSquadData(squadPayload));
    }
  };

  const handleTeamChange = (id) => {
    if (id) {
      dispatch(
        getSquadData({
          seasonId: isEmpty(matchDetailState?.matchData?.season_id)
            ? ""
            : matchDetailState?.matchData?.season_id,
          teamId: id,
        })
      );
    }
  };

  return (
    <div className="container pt-3">
      {matchDetailState.matchDataLoader ? (
        <Loader />
      ) : matchDetailState.matchDataLoader === false &&
        isEmpty(matchDetailState?.matchData) ? (
        <EmptyResult text='Currently, there are no live matches. Kindly check the "Upcoming" tab for details on forthcoming matches.' />
      ) : (
        <>
          <MatchCard
            matchData={
              isEmpty(matchDetailState?.matchData)
                ? ""
                : matchDetailState?.matchData
            }
          />
          <Tabs
            tabNames={["INFO", "SCORECARD", "SQUAD"]}
            isSticky={true}
            handleTabChange={handleTabChange}
          >
            <div className="tab-container">
              <div className="tab-content active" id="info">
                {/* Live */}
                {isEmpty(matchDetailState?.matchData?.status)
                  ? ""
                  : matchDetailState?.matchData?.status.toUpperCase() !==
                      "FINISHED" &&
                    matchDetailState?.matchData?.status !== "NS" && (
                      <>
                        {isEmpty(matchDetailState?.matchData.scoreboards)
                          ? ""
                          : matchDetailState?.matchData.scoreboards
                              .filter(
                                (scoreboard) => scoreboard.type === "total"
                              )
                              .sort((a, b) =>
                                a.scoreboard > b.scoreboard ? 1 : -1
                              )
                              .map((sb, index) => (
                                <CustomAccordion
                                  key={index}
                                  title={`${sb?.team?.name?.toUpperCase()}`}
                                  inning={`${stringifyNumber(
                                    parseInt(
                                      sb?.scoreboard.charAt(
                                        sb?.scoreboard.length - 1
                                      )
                                    )
                                  )} innings`}
                                  desc={`${sb?.total}/${sb?.wickets} (${sb?.overs} Overs)`}
                                  active={index === 0 ? true : false}
                                  customClass={`accordion-${index}`}
                                >
                                  {matchDetailState?.matchData?.batting && (
                                    <BatsmenList
                                      batting={
                                        matchDetailState?.matchData?.batting
                                      }
                                      sbType={sb?.scoreboard}
                                      scoreboard={
                                        matchDetailState?.matchData?.scoreboards
                                      }
                                      current={true}
                                    />
                                  )}
                                </CustomAccordion>
                              ))}
                      </>
                    )}

                {/* COMPLETED */}
                {isEmpty(matchDetailState?.matchData?.status)
                  ? ""
                  : matchDetailState?.matchData?.status.toUpperCase() ===
                      "FINISHED" && (
                      <>
                        {isEmpty(matchDetailState?.matchData.scoreboards)
                          ? ""
                          : matchDetailState?.matchData.scoreboards
                              .filter(
                                (scoreboard) => scoreboard.type === "total"
                              )
                              .sort((a, b) =>
                                a.scoreboard > b.scoreboard ? 1 : -1
                              )
                              .map((sb, index) => (
                                <CustomAccordion
                                  key={index}
                                  title={`${sb?.team?.name?.toUpperCase()}`}
                                  inning={`${stringifyNumber(
                                    parseInt(
                                      sb?.scoreboard.charAt(
                                        sb?.scoreboard.length - 1
                                      )
                                    )
                                  )} innings`}
                                  desc={`${sb?.total}/${sb?.wickets} (${sb?.overs} Overs)`}
                                  active={index === 0 ? true : false}
                                  customClass={`accordion-${index}`}
                                >
                                  {matchDetailState?.matchData?.batting && (
                                    <BatsmenList
                                      batting={
                                        matchDetailState?.matchData?.batting
                                      }
                                      sbType={sb?.scoreboard}
                                      scoreboard={
                                        matchDetailState?.matchData?.scoreboards
                                      }
                                      current={true}
                                    />
                                  )}
                                </CustomAccordion>
                              ))}
                      </>
                    )}
                <MatchDetailTable matchData={matchDetailState?.matchData} />
              </div>
              <div className="tab-content" id="scorecard">
                {isEmpty(matchDetailState?.matchData?.status) ? (
                  ""
                ) : matchDetailState?.matchData?.status === "NS" ? (
                  <EmptyResult text="Score updates are not available at this time, please check back once the match is started." />
                ) : isEmpty(matchDetailState?.matchData.scoreboards) ? (
                  ""
                ) : (
                  matchDetailState?.matchData.scoreboards
                    .filter((scoreboard) => scoreboard.type === "total")
                    .sort((a, b) => (a.scoreboard > b.scoreboard ? 1 : -1))
                    .map((sb, index) => (
                      <CustomAccordion
                        key={index}
                        title={`${sb?.team?.name?.toUpperCase()}`}
                        inning={`${stringifyNumber(
                          parseInt(
                            sb?.scoreboard.charAt(sb?.scoreboard.length - 1)
                          )
                        )} innings`}
                        desc={`${sb?.total}/${sb?.wickets} (${sb?.overs} Overs)`}
                        active={index === 0 ? true : false}
                        customClass={`accordion-${index}`}
                      >
                        {matchDetailState?.matchData?.batting && (
                          <BatsmenList
                            batting={matchDetailState?.matchData?.batting}
                            sbType={sb?.scoreboard}
                            scoreboard={
                              matchDetailState?.matchData?.scoreboards
                            }
                          />
                        )}

                        {matchDetailState?.matchData?.bowling && (
                          <BowlerList
                            bowling={matchDetailState?.matchData?.bowling}
                            sbType={sb?.scoreboard}
                          />
                        )}
                      </CustomAccordion>
                    ))
                )}
              </div>
              <div className="tab-content" id="squad">
                {isEmpty(matchDetailState?.matchData?.status) ? (
                  ""
                ) : matchDetailState?.matchData?.status === "NS" ? (
                  <EmptyResult text="Squads are not available at this time, please check back once the match is started." />
                ) : (
                  <>
                    <TeamSwitcher
                      teams={teams}
                      onTeamChange={handleTeamChange}
                    />
                    <TeamWrapper squadState={squadState} />
                  </>
                )}
              </div>
            </div>
          </Tabs>
        </>
      )}
    </div>
  );
}

export default Detail;
