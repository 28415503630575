import { baseUrl } from "../config";
export const getMatchDetailDataApi = async (matchId) => {
  try {
    let url = `${baseUrl}?type=match-details&matchid=${matchId}&include=runs.team,scoreboards,scoreboards.team,bowling,bowling.bowler,batting.batsman,batting,tosswon,firstumpire,secondumpire,tvumpire,referee,localteam,venue,visitorteam,localteam.country,venue.country,stage`;
    const matchDataResponse = await fetch(url, {
      method: "GET",
    });
    return await matchDataResponse.json();
  } catch (errors) {
    return errors;
  }
};
