import { call, put, takeEvery } from 'redux-saga/effects';
import { getSquadDataSuccess } from '../actions/SquadAction';
import { getSquadDataApi } from '../apis/SquadApi';
import {
  // GET_SQUAD_DATA_SUCCESS,
  GET_SQUAD_DATA,
  GET_SQUAD_DATA_ERROR,
} from '../constants/ActionTypes';

function* fetchSquads(action) {
  try {
    const response = yield call(getSquadDataApi, action.payload);
    // console.log('uuu', response);
    if (response !== null && response !== undefined) {
      if (response?.status === 'error') {
        yield put({
          type: GET_SQUAD_DATA_ERROR,
          squadDataError: response?.status,
        });
      } else {
        yield put(getSquadDataSuccess(response.data));
      }
    } else {
      yield put({
        type: GET_SQUAD_DATA_ERROR,
        squadDataError: 'Something went wrong!',
      });
    }
  } catch (e) {
    yield put({
      type: GET_SQUAD_DATA_ERROR,
      squadDataError: e.message,
    });
  }
}

export default function* waitForFetchSquad() {
  yield takeEvery(GET_SQUAD_DATA, fetchSquads);
}
