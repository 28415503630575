import {
  SET_MATCH_DETAIL_DATA,
  GET_MATCH_DETAIL_DATA,
  GET_MATCH_DETAIL_DATA_SUCCESS,
  GET_MATCH_DETAIL_DATA_ERROR,
} from '../constants/ActionTypes';

const INIT_MATCH_DATA = {
  matchDataLoader: false,
  matchData: null,
  matchDataError: null,
};

const MatchDetailReducer = (state = INIT_MATCH_DATA, action) => {
  switch (action.type) {
    case SET_MATCH_DETAIL_DATA: {
      return {
        ...state,
        matchData: action?.payload,
        matchDataLoader: false,
        matchDataError: null,
      };
    }
    case GET_MATCH_DETAIL_DATA: {
      return {
        ...state,
        matchDataLoader: true,
      };
    }
    case GET_MATCH_DETAIL_DATA_SUCCESS: {
      return {
        ...state,
        matchData: action?.payload,
        matchDataLoader: false,
        matchDataError: null,
      };
    }
    case GET_MATCH_DETAIL_DATA_ERROR: {
      console.log('match detail error', action.payload);
      return {
        ...state,
        matchDataError: action.payload,
        matchDataLoader: false,
      };
    }
    default:
      return state;
  }
};

export default MatchDetailReducer;
