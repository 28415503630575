import $ from 'jquery';
export const loadJQuery = () => {
  $(document).ready(function () {
    $('.tabs a').click(function (e) {
      $('.tabs a').removeClass('active');
      $(this).addClass('active');
      var activeTab = $(this).attr('rel');
      $('.tab-content').hide();
      $('#' + activeTab).fadeIn();
      e.preventDefault();
    });

    $('.team-switcher a').click(function (e) {
      var active = $(this).attr('rel');
      $('.team-switcher a').removeClass('active');
      $(this).addClass('active');
      $('.team-wrapper .team').hide();
      $('.team-wrapper #' + active).fadeIn();
      e.preventDefault();
    });
  });
};
