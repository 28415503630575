import React from 'react';

function TeamSwitcher({ teams, onTeamChange }) {
  // console.log('ttt', teams);
  return (
    <div className="team-switcher">
      {teams &&
        teams.map((team, index) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            key={index}
            href="#"
            className={`btn ${index === 0 && 'active'}`}
            rel={team.rel}
            onClick={() => onTeamChange(team.id)}
          >
            {team.name}
          </a>
        ))}
      <span></span>
    </div>
  );
}

export default TeamSwitcher;
