import React from "react";
import { Accordion } from "react-bootstrap";

function CustomAccordion({
  title,
  desc,
  active = false,
  customClass,
  children,
  inning = null,
}) {
  return (
    <div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="accordion-custom-header">
              <div>
                {title} <i> ({inning})</i>
              </div>
              <div className="accordion-custom-header-desc">{desc}</div>
            </div>
          </Accordion.Header>
          <Accordion.Body>{children}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default CustomAccordion;
