export const toQueryString = (params) => {
  var str = "";
  for (var name in params) {
    str += name + "=" + params[name] + "&";
  }
  return str.slice(0, -1);
};

export const getFilterStatus = (activeTab) => {
  if (activeTab.toLowerCase() === "live") {
    return "1st Innings,2nd Innings,3rd Innings,4th Innings,Stump Day 1,Stump Day 2,Stump Day 3,Stump Day 4,Innings Break";
  } else if (activeTab.toLowerCase() === "completed") {
    return "Finished";
  } else if (activeTab.toLowerCase() === "upcoming") {
    return "NS";
  } else {
    return "";
  }
};

export const stringifyNumber = (n) => {
  // console.log("str: " + str);
  // let n = str.charAt(str.length - 1);
  var special = [
    "zeroth",
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelfth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
  ];
  var deca = [
    "twent",
    "thirt",
    "fort",
    "fift",
    "sixt",
    "sevent",
    "eight",
    "ninet",
  ];

  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + "ieth";
  return deca[Math.floor(n / 10) - 2] + "y-" + special[n % 10];
};

export const isEmpty = (value) => {
  return value === undefined || value === null || value.length <= 0
    ? true
    : false;
};
