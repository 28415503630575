import {
  GET_SQUAD_DATA,
  GET_SQUAD_DATA_SUCCESS,
  GET_SQUAD_DATA_ERROR,
} from '../constants/ActionTypes';

const INIT_MATCH_DATA = {
  squadDataLoader: false,
  squadData: null,
  squadDataError: null,
};

const SquadReducer = (state = INIT_MATCH_DATA, action) => {
  switch (action.type) {
    case GET_SQUAD_DATA: {
      return {
        ...state,
        squadDataLoader: true,
      };
    }
    case GET_SQUAD_DATA_SUCCESS: {
      return {
        ...state,
        squadData: action?.payload,
        squadDataLoader: false,
        squadDataError: null,
      };
    }
    case GET_SQUAD_DATA_ERROR: {
      return {
        ...state,
        squadDataError: action.payload,
        squadDataLoader: false,
      };
    }
    default:
      return state;
  }
};

export default SquadReducer;
