import {
  GET_MATCH_DATA,
  GET_MATCH_DATA_SUCCESS,
  GET_MATCH_DATA_ERROR,
  GET_COMPLETED_MATCH_DATA,
  GET_COMPLETED_MATCH_DATA_SUCCESS,
  GET_COMPLETED_MATCH_DATA_ERROR,
  GET_UPCOMING_MATCH_DATA,
  GET_UPCOMING_MATCH_DATA_SUCCESS,
  GET_UPCOMING_MATCH_DATA_ERROR,
} from '../constants/ActionTypes';

export const getMatchData = (payload) => {
  return {
    type: GET_MATCH_DATA,
    payload: payload,
  };
};

export const getMatchDataSuccess = (matchData) => {
  return {
    type: GET_MATCH_DATA_SUCCESS,
    payload: matchData,
  };
};

export const getMatchDataError = (error) => {
  return {
    type: GET_MATCH_DATA_ERROR,
    payload: error,
  };
};

// For upcoming matches
export const getCompletedMatchData = (payload) => {
  return {
    type: GET_COMPLETED_MATCH_DATA,
    payload: payload,
  };
};

export const getCompletedMatchDataSuccess = (matchData) => {
  return {
    type: GET_COMPLETED_MATCH_DATA_SUCCESS,
    payload: matchData,
  };
};

export const getCompletedMatchDataError = (error) => {
  return {
    type: GET_COMPLETED_MATCH_DATA_ERROR,
    payload: error,
  };
};

// for upcoming matches

export const getUpcomingMatchData = (payload) => {
  return {
    type: GET_UPCOMING_MATCH_DATA,
    payload: payload,
  };
};

export const getUpcomingMatchDataSuccess = (matchData) => {
  return {
    type: GET_UPCOMING_MATCH_DATA_SUCCESS,
    payload: matchData,
  };
};

export const getUpcomingMatchDataError = (error) => {
  return {
    type: GET_UPCOMING_MATCH_DATA_ERROR,
    payload: error,
  };
};
