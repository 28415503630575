import React from 'react';

function TableRowSimple({ text, value, isHeader = false }) {
  return (
    <li
      className={`${
        !isHeader ? 'ml-auto' : 'header'
      } d-flex align-content-between`}
    >
      <h4>{text}</h4>
      <span style={{ width: '100%', textAlign: 'right' }}>{value}</span>
    </li>
  );
}

export default TableRowSimple;
