import {
  GET_SQUAD_DATA,
  GET_SQUAD_DATA_SUCCESS,
  GET_SQUAD_DATA_ERROR,
} from '../constants/ActionTypes';

export const getSquadData = (payload) => {
  return {
    type: GET_SQUAD_DATA,
    payload: payload,
  };
};

export const getSquadDataSuccess = (squadData) => {
  return {
    type: GET_SQUAD_DATA_SUCCESS,
    payload: squadData,
  };
};

export const getSquadDataError = (error) => {
  return {
    type: GET_SQUAD_DATA_ERROR,
    payload: error,
  };
};
