import React from 'react';
function Tabs({
  tabNames,
  children,
  isSticky = false,
  handleTabChange = null,
}) {
  return (
    <>
      <div className={`tabs ${isSticky ? 'sticky' : ''}`}>
        {tabNames &&
          tabNames.map((tabName, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              key={index}
              onClick={() => handleTabChange(tabName)}
              href="#"
              className={index === 0 ? 'active' : ''}
              rel={tabName.toLowerCase()}
            >
              {tabName}
            </a>
          ))}
      </div>
      {children}
    </>
  );
}

export default Tabs;
