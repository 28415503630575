import { baseUrl } from "../config";
export const getSquadDataApi = async (payload) => {
  try {
    let url = `${baseUrl}?type=match-squad&teamid=${payload.teamId}&seasonid=${payload.seasonId}`;
    const squadDataResponse = await fetch(url, {
      method: "GET",
    });
    return await squadDataResponse.json();
  } catch (errors) {
    return errors;
  }
};
