import React from 'react';
import SquadSkeleton from './common/SquadSkeleton';
import TeamPlayerList from './TeamPlayerList';

function TeamWrapper({ squadState }) {
  return (
    <div className="team-wrapper">
      <div className="team active" id="team-one">
        {squadState?.squadDataLoader ? (
          <SquadSkeleton />
        ) : (
          <TeamPlayerList players={squadState.squadData?.squad} />
        )}
      </div>
      <div className="team" id="team-two">
        {squadState?.squadDataLoader ? (
          <SquadSkeleton />
        ) : (
          <TeamPlayerList players={squadState.squadData?.squad} />
        )}
      </div>
    </div>
  );
}

export default TeamWrapper;
