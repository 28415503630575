import './assets/scss/style.scss';
import AppRouter from './routes';
// import './assets/js/init';
// import './App.css';

function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
