import { all } from '@redux-saga/core/effects';
import waitForFetchMatchDetail from './MatchDetailSaga';
import {
  waitForFetchMatches,
  waitForFetchCompletedMatches,
  waitForFetchUpcomingMatches,
} from './MatchSaga';
import waitForFetchSquad from './SquadSaga';
export default function* rootSaga() {
  yield all([
    waitForFetchMatches(),
    waitForFetchCompletedMatches(),
    waitForFetchUpcomingMatches(),
    waitForFetchMatchDetail(),
    waitForFetchSquad(),
  ]);
}
