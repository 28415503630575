import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_COMPLETED_MATCH_DATA,
  GET_COMPLETED_MATCH_DATA_ERROR,
  GET_MATCH_DATA,
  GET_MATCH_DATA_ERROR,
  GET_UPCOMING_MATCH_DATA,
  GET_UPCOMING_MATCH_DATA_ERROR,
} from '../constants/ActionTypes';
import { getMatchDataApi } from '../apis/MatchApi';
import {
  getCompletedMatchDataSuccess,
  getMatchDataSuccess,
  getUpcomingMatchDataSuccess,
} from '../actions/MatchAction';

function* fetchMatches(action) {
  try {
    const response = yield call(getMatchDataApi, action.payload);
    yield put(getMatchDataSuccess(response));
  } catch (e) {
    yield put({ type: GET_MATCH_DATA_ERROR, matchDataError: e.message });
  }
}

// for completed

function* fetchCompletedMatches(action) {
  try {
    const response = yield call(getMatchDataApi, action.payload);
    yield put(getCompletedMatchDataSuccess(response));
  } catch (e) {
    yield put({
      type: GET_COMPLETED_MATCH_DATA_ERROR,
      matchDataError: e.message,
    });
  }
}
// for upcoming
function* fetchUpcomingMatches(action) {
  try {
    const response = yield call(getMatchDataApi, action.payload);
    yield put(getUpcomingMatchDataSuccess(response));
  } catch (e) {
    yield put({
      type: GET_UPCOMING_MATCH_DATA_ERROR,
      matchDataError: e.message,
    });
  }
}

export function* waitForFetchMatches() {
  yield takeEvery(GET_MATCH_DATA, fetchMatches);
}

export function* waitForFetchCompletedMatches() {
  yield takeEvery(GET_COMPLETED_MATCH_DATA, fetchCompletedMatches);
}

export function* waitForFetchUpcomingMatches() {
  yield takeEvery(GET_UPCOMING_MATCH_DATA, fetchUpcomingMatches);
}
