function TableRow({ text, columns, isHeader = false }) {
  return (
    <li
      className={`${
        !isHeader ? 'ml-auto' : 'header'
      } d-flex align-items-center`}
    >
      <h4>{text}</h4>
      {columns &&
        columns.map((column, index) => (
          <span key={index} className={index === 0 ? 'ml-auto':''}>
            {column}
          </span>
        ))}
    </li>
  );
}

export default TableRow;
