import {
  SET_MATCH_DETAIL_DATA,
  GET_MATCH_DETAIL_DATA,
  GET_MATCH_DETAIL_DATA_SUCCESS,
  GET_MATCH_DETAIL_DATA_ERROR,
} from '../constants/ActionTypes';

export const setMatchDetailData = (payload) => {
  return {
    type: SET_MATCH_DETAIL_DATA,
    payload: payload,
  };
};

export const getMatchDetailData = (payload) => {
  return {
    type: GET_MATCH_DETAIL_DATA,
    payload: payload,
  };
};

export const getMatchDetailDataSuccess = (matchDetailData) => {
  return {
    type: GET_MATCH_DETAIL_DATA_SUCCESS,
    payload: matchDetailData,
  };
};

export const getMatchDetailDataError = (error) => {
  return {
    type: GET_MATCH_DETAIL_DATA_ERROR,
    payload: error,
  };
};
