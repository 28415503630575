import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getCompletedMatchData,
  getMatchData,
  getUpcomingMatchData,
} from "../actions/MatchAction";
import { loadJQuery } from "../assets/js/init";
import EmptyResult from "../components/common/EmptyResult";
import Loader from "../components/common/Loader";
import Tabs from "../components/common/Tabs";
import MatchCardList from "../components/MatchCardList";
import { getFilterStatus } from "../helpers/Helper";
import InfiniteScroll from "react-infinite-scroll-component";
const _ = require("lodash");

let initialRendering = 0;
function Home() {
  loadJQuery();
  const baseFilter =
    "runs.team,localteam,visitorteam,localteam.country,venue.country,batting.team,stage";
  const [currentActiveTab, setcurrentActiveTab] = useState("LIVE");
  const [queryParams, setQueryParams] = useState({
    tab: currentActiveTab,
    page: 1,
    per_page: 10,
    sort: "-starting_at",
    include: baseFilter,
    "filter[status]": getFilterStatus(currentActiveTab),
  });

  const matchState = useSelector((state) => state.matches);
  const dispatch = useDispatch();

  const handleTabChange = (currentActiveTab) => {
    setcurrentActiveTab(currentActiveTab);
    initialRendering = 0;
    let filter = {
      tab: currentActiveTab,
      page: 1,
      per_page: 10,
      sort: "-starting_at",
      include: baseFilter,
      "filter[status]": getFilterStatus(currentActiveTab),
    };
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      "filter[status]": getFilterStatus(currentActiveTab),
    }));

    if (currentActiveTab === "COMPLETED") {
      dispatch(getCompletedMatchData(filter));
    } else if (currentActiveTab === "UPCOMING") {
      dispatch(getUpcomingMatchData({ ...filter, sort: "+starting_at" }));
    } else if (currentActiveTab === "LIVE") {
      dispatch(getMatchData(filter));
    }
  };

  useEffect(() => {
    if (currentActiveTab === "LIVE") {
      dispatch(getMatchData(queryParams));
    }
  }, [queryParams, currentActiveTab, dispatch]);

  const fetchData = () => {
    ++initialRendering;
    if (
      matchState?.matchMetaData?.last_page !== null &&
      matchState?.matchMetaData?.last_page !== undefined &&
      queryParams.page !== matchState?.matchMetaData?.last_page
    ) {
      setQueryParams((prevState) => ({
        ...prevState,
        page: ++queryParams.page,
        "filter[status]": getFilterStatus(currentActiveTab),
      }));

      if (currentActiveTab === "COMPLETED") {
        dispatch(getCompletedMatchData(queryParams));
      } else if (currentActiveTab === "UPCOMING") {
        dispatch(
          getUpcomingMatchData({ ...queryParams, sort: "+starting_at" })
        );
      } else if (currentActiveTab === "LIVE") {
        dispatch(getMatchData(queryParams));
      }
    }
  };

  return (
    <div className="container">
      <Tabs
        handleTabChange={handleTabChange}
        tabNames={["LIVE", "COMPLETED", "UPCOMING"]}
      >
        {currentActiveTab === "LIVE" ? (
          matchState.matchDataLoader === true && initialRendering === 0 ? (
            <Loader />
          ) : matchState.matchDataLoader === false &&
            matchState.matchData.length === 0 ? (
            <EmptyResult text='Currently, there are no live matches. Kindly check the "Upcoming" tab for details on forthcoming matches.' />
          ) : (
            <InfiniteScroll
              dataLength={matchState.matchData.length}
              next={fetchData}
              hasMore={
                queryParams.page !== matchState?.matchMetaData?.last_page
              }
            >
              <MatchCardList
                activeTab={currentActiveTab}
                // result={matchState.matchData}
                result={_.sortBy(matchState.matchData, function (obj) {
                  return _.indexOf(
                    ["T20I", "ODI", "Test", "T20", "T10", "Test/5day", "4day"],
                    obj.type
                  );
                })}
              />
            </InfiniteScroll>
          )
        ) : (
          ""
        )}

        {currentActiveTab === "COMPLETED" ? (
          matchState.matchDataLoader === true && initialRendering === 0 ? (
            <Loader />
          ) : matchState.matchDataLoader === false &&
            matchState.matchCompletedData.length === 0 ? (
            <EmptyResult text='Currently, there are no completed matches. Kindly check the "Upcoming" tab for details on forthcoming matches.' />
          ) : (
            <InfiniteScroll
              dataLength={matchState.matchCompletedData.length}
              next={fetchData}
              hasMore={
                queryParams.page !== matchState?.matchCompletedData?.last_page
              }
            >
              <MatchCardList
                activeTab={currentActiveTab}
                result={matchState.matchCompletedData}
              />
            </InfiniteScroll>
          )
        ) : (
          ""
        )}

        {currentActiveTab === "UPCOMING" ? (
          matchState.matchDataLoader === true && initialRendering === 0 ? (
            <Loader />
          ) : matchState.matchDataLoader === false &&
            matchState.matchUpcomingData.length === 0 ? (
            <EmptyResult text="Currently, there are no upcomming matches." />
          ) : (
            <InfiniteScroll
              dataLength={matchState.matchUpcomingData.length}
              next={fetchData}
              hasMore={
                queryParams.page !== matchState?.matchMetaData?.last_page
              }
            >
              <MatchCardList
                activeTab={currentActiveTab}
                result={matchState.matchUpcomingData}
              />
            </InfiniteScroll>
          )
        ) : (
          ""
        )}
      </Tabs>
    </div>
  );
}

export default Home;
