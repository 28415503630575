import React from 'react';
import MatchCard from './common/MatchCard';

function MatchCardList({ activeTab, result }) {
  return (
    <div>
      <div className="tab-container">
        <div className="tab-content active" id={activeTab}>
          {result.length > 0 &&
            result.map((matchData, index) => (
              <MatchCard key={index} matchData={matchData} />
            ))}
        </div>
      </div>
    </div>
  );
}

export default MatchCardList;
