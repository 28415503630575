import React, { useEffect, useState } from "react";
import RowGroup from "./common/RowGroup";
import TableRow from "./common/TableRow";
import moment from "moment";
import { isEmpty } from "../helpers/Helper";

function MatchDetailTable({ matchData }) {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (
      matchData?.starting_at !== null &&
      matchData?.starting_at !== undefined
    ) {
      setDate(moment(matchData?.starting_at).format("Do MMMM YYYY"));
      setTime(moment(matchData?.starting_at).format("h:mm:ss a"));
    }
  }, [matchData?.starting_at]);

  return (
    <RowGroup groupClass="match-detail">
      <TableRow text="Match Details" isHeader={true} />
      <TableRow text="Date :" columns={[date]} />
      <TableRow text="Time :" columns={[time]} />
      <TableRow
        text="Venue :"
        columns={[
          `${isEmpty(matchData?.venue?.name) ? "" : matchData?.venue?.name}, ${
            isEmpty(matchData?.venue?.city) ? "" : matchData?.venue?.city
          }, ${
            isEmpty(matchData?.venue?.country?.name)
              ? ""
              : matchData?.venue?.country?.name
          }`,
        ]}
      />
      {isEmpty(matchData?.tosswon?.name) ? (
        ""
      ) : (
        <TableRow
          text="Toss :"
          columns={[
            `${
              isEmpty(matchData?.tosswon?.name) ? "" : matchData?.tosswon?.name
            } won the toss & elected to ${
              isEmpty(matchData?.elected) ? "" : matchData?.elected
            }`,
          ]}
        />
      )}
      {isEmpty(matchData?.firstumpire?.firstname) ? (
        ""
      ) : (
        <TableRow
          text="Umpires :"
          columns={[
            `${
              isEmpty(matchData?.firstumpire?.firstname)
                ? ""
                : matchData?.firstumpire?.firstname
            } ${
              isEmpty(matchData?.firstumpire?.lastname)
                ? ""
                : matchData?.firstumpire?.lastname
            }`,
            `${
              isEmpty(matchData?.secondumpire)
                ? ""
                : `, ${
                    isEmpty(matchData?.secondumpire?.firstname)
                      ? ""
                      : matchData?.secondumpire?.firstname
                  } ${
                    isEmpty(matchData?.secondumpire?.lastname)
                      ? ""
                      : matchData?.secondumpire?.lastname
                  }`
            }`,
          ]}
        />
      )}
      {isEmpty(matchData?.tvumpire?.firstname) ? (
        ""
      ) : (
        <TableRow
          text="Tv Umpires :"
          columns={[
            `${
              isEmpty(matchData?.tvumpire?.firstname)
                ? ""
                : matchData?.tvumpire?.firstname
            } ${
              isEmpty(matchData?.tvumpire?.lastname)
                ? ""
                : matchData?.tvumpire?.lastname
            }`,
          ]}
        />
      )}
      {matchData?.referee !== null && (
        <TableRow
          text="Match Refree :"
          columns={[
            `${
              isEmpty(matchData?.referee?.firstname)
                ? ""
                : matchData?.referee?.firstname
            } ${
              isEmpty(matchData?.referee?.lastname)
                ? ""
                : matchData?.referee?.lastname
            }`,
          ]}
        />
      )}
    </RowGroup>
  );
}

export default MatchDetailTable;
