import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
function SquadSkeleton() {
  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <p>
        <Skeleton count={6} style={{ height: 50 }} />
      </p>
    </SkeletonTheme>
  );
}

export default SquadSkeleton;
